import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.5_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwright+test@1.45.2_react-dom@18._ym4h2fy563q25sb2jln43cmjoi/node_modules/next/dist/client/components/app-router.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.5_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwright+test@1.45.2_react-dom@18._ym4h2fy563q25sb2jln43cmjoi/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.5_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwright+test@1.45.2_react-dom@18._ym4h2fy563q25sb2jln43cmjoi/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.5_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwright+test@1.45.2_react-dom@18._ym4h2fy563q25sb2jln43cmjoi/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.5_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwright+test@1.45.2_react-dom@18._ym4h2fy563q25sb2jln43cmjoi/node_modules/next/dist/client/components/not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.5_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwright+test@1.45.2_react-dom@18._ym4h2fy563q25sb2jln43cmjoi/node_modules/next/dist/client/components/render-from-template-context.js");
